@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/* .chat-start .chat-bubble {
    @apply rounded-tl-none rounded-bl-[1rem];
}
.chat-end .chat-bubble {
    @apply rounded-tr-none rounded-br-[1rem];
}
.chat-bubble:before {
    @apply top-0 [transform:rotateX(180deg)];
} */

.first-msg:before,
.middle-msg:before,
.end-first-msg:before,
.end-middle-msg:before {
    display: none;
}

.middle-msg,
.last-msg {
    @apply rounded-tl-none;
}

.end-middle-msg,
.end-last-msg {
    @apply rounded-tr-none;
}

.chat {
    @apply pb-4;
    @apply space-y-1;
}

.chat-bubble {
    @apply text-white;
}

.custom-bg {
    @apply w-full h-full; /* Ensure full width and height */
    background-size: 100% 100%;
    background-position: 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0, 0 0;
    background-image: radial-gradient(18% 28% at 24% 50%, #cefaffff 7%, #073aff00 100%),
        radial-gradient(18% 28% at 18% 71%, #ffffff59 6%, #073aff00 100%),
        radial-gradient(70% 53% at 36% 76%, #ccffb8ff 0%, #073aff00 100%),
        radial-gradient(42% 53% at 15% 94%, #ffffff 7%, #073aff00 100%),
        radial-gradient(42% 53% at 34% 72%, #ffffff 7%, #073aff00 100%),
        radial-gradient(18% 28% at 35% 87%, #ffffff 7%, #073aff00 100%),
        radial-gradient(31% 43% at 7% 98%, #ffffff 24%, #073aff00 100%),
        radial-gradient(21% 37% at 72% 23%, #ff6d6d9c 24%, #073aff00 100%),
        radial-gradient(35% 56% at 91% 74%, #8a4fff 9%, #073aff00 100%),
        radial-gradient(74% 86% at 67% 38%, #ffb66d 24%, #073aff00 100%),
        linear-gradient(125deg, #4eb5ff 1%, #4c00fcff 100%);
}
